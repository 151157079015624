'use client'

type UseDownloadFile = () => (fileUrl: string, fileName?: string) => void

/**
 * if we need to execute a browser file download operation but we cannot
 * render a standard `<a>` element (for whatever reason), we can use this "hack"
 * @NOTE please rely on the HTML standard `<a href="..." download>` element
 * whenever possible & treat this function as a last resort
 */
export const useDownloadFile: UseDownloadFile = () => {
  return (fileUrl, fileName) => {
    const linkElement = window.document.createElement('a')
    window.document.querySelector('body')?.append(linkElement)
    linkElement.setAttribute('href', fileUrl)
    linkElement.setAttribute('target', '_blank')
    linkElement.setAttribute('rel', 'noopener noreferrer')
    linkElement.style.display = 'none'
    if (fileName !== undefined) {
      linkElement.setAttribute('download', fileName)
    }
    linkElement.click()
    linkElement.remove()
  }
}
