import { type ISbStoryData } from '@storyblok/react'

import { cmsStoryblokSlugToPublicUrl } from '../../cmsStoryblokSlugToPublicUrl'
import { storyAccessorGetUrlFromMultiLink } from '../storyAccessorGetUrlFromMultiLink'
import { type StoryblokPageContentType } from '../storyblokPageContentTypes'

/**
 * returns seoCanonical:
 * - if linked story -> absolute path with domain/host
 * - if string -> return as is
 * - if empty -> return full absolute url for story
 */
export const storyAccessorGetCanonicalUrl = (
  story: ISbStoryData<StoryblokPageContentType>,
): string => {
  const seoCanonical = storyAccessorGetUrlFromMultiLink(
    story.content.seoCanonical,
    {
      addTenantHostFromPathPrefix: true,
    },
  )

  if (seoCanonical) {
    return seoCanonical
  }

  return cmsStoryblokSlugToPublicUrl(story.full_slug, {
    addTenantHostFromPathPrefix: true,
  })
}
