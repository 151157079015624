import { apiPlugin, storyblokInit } from '@storyblok/react/rsc'

import {
  processEnvAccessorGetStoryblokAccessToken,
  processEnvAccessorGetStoryblokCacheServiceUrl,
} from '../process-env'

import { STORYBLOK_COMPONENTS_MAP } from './components/STORYBLOK_COMPONENTS_MAP'

/**
 * Provides Storyblok client on server side for App Router and React Server Components
 */
export const cmsGetServerStoryblokApi = storyblokInit({
  accessToken: processEnvAccessorGetStoryblokAccessToken(),
  apiOptions: {
    endpoint: processEnvAccessorGetStoryblokCacheServiceUrl(),
  },
  components: STORYBLOK_COMPONENTS_MAP,
  use: [apiPlugin],
})
