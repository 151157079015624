import NextLink from 'next/link'
import { type FC } from 'react'

import { Link } from 'base-ui'

import { type AlgoliaRecord } from '../../../algolia'
import { urlGetRelativeUrl } from '../../../url-handling'

type Props = {
  product: AlgoliaRecord
}

export const StoryblokProductBatteryName: FC<Props> = ({ product }) => {
  return (
    <Link<typeof NextLink>
      className="line-clamp-3 overflow-hidden text-ellipsis font-medium text-dark-primary-max no-underline"
      data-analytics="item-title"
      data-qa-id={`form-product-slider-${product.objectID}-link-button`}
      href={urlGetRelativeUrl(product.deeplink)}
      slots={{ root: NextLink }}
      title={product.productName}
    >
      {product.productName}
    </Link>
  )
}
