'use client'

import { type FC, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button } from 'base-ui'

import { type AlgoliaRecord } from '../../../algolia'
import { StoryblokProductBatteryItem } from '../StoryblokProductBattery/StoryblokProductBatteryItem'

type StoryblokProductBatteryGridProps = {
  products: AlgoliaRecord[]
}

const PRODUCTS_PER_PAGE = 8

export const StoryblokProductBatteryGrid: FC<
  StoryblokProductBatteryGridProps
> = ({ products }) => {
  const [productVisibleCount, setProductVisibleCount] =
    useState(PRODUCTS_PER_PAGE)

  return (
    <div className="flex flex-col items-center gap-4 tablet:gap-6">
      <ul className="grid w-full grid-cols-2 gap-4 tablet:grid-cols-4 tablet:gap-y-6">
        {products.slice(0, productVisibleCount).map((product) => (
          <li key={product.objectID}>
            <StoryblokProductBatteryItem product={product} />
          </li>
        ))}
      </ul>
      {productVisibleCount < products.length && (
        <Button
          onClick={() => {
            setProductVisibleCount((count) => count + PRODUCTS_PER_PAGE)
          }}
          size="large"
          variant="outlined"
        >
          <FormattedMessage id="general.label.loadMoreProducts" />
        </Button>
      )}
    </div>
  )
}
