import { getImageProps } from 'next/image'

import { useGlobalConfigContext } from '../global-config'

import {
  brandIllustrationGetImage,
  type BrandIllustrationName,
  type BrandIllustrationSize,
  type BrandIllustrationType,
} from './brandIllustrationGetImage'

export const useBrandIllustrationImageProps = ({
  name,
  size,
  type,
}: {
  name: BrandIllustrationName
  size: BrandIllustrationSize
  type: BrandIllustrationType
}): ReturnType<typeof getImageProps>['props'] => {
  const {
    cdn: { homeOneAssetsUrl },
  } = useGlobalConfigContext()

  const image = brandIllustrationGetImage({
    cdnUrl: homeOneAssetsUrl,
    name,
    size,
    type,
  })

  const image2x = brandIllustrationGetImage({
    cdnUrl: homeOneAssetsUrl,
    name,
    scale: 2,
    size,
    type,
  })

  const { props: imageProps } = getImageProps({
    alt: '',
    unoptimized: true,
    ...image,
  })

  return {
    ...imageProps,
    srcSet: `${image.src}, ${image2x.src} 2x`,
  }
}
