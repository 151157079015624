import { type PolymorphicProps, useSlotProps } from '@mui/base/utils'
import { type ComponentProps, type ElementType, type ReactElement } from 'react'

type ProductCardOwnProps<RootComponentType extends ElementType> = {
  slotProps?: {
    root?: ComponentProps<RootComponentType>
  }
  slots?: {
    root?: RootComponentType
  }
}

interface ProductCardTypeMap<
  RootComponentType extends ElementType = 'section',
> {
  defaultComponent: RootComponentType
  props: ProductCardOwnProps<RootComponentType>
}

type ProductCardProps<
  RootComponentType extends
    ElementType = ProductCardTypeMap['defaultComponent'],
> = PolymorphicProps<ProductCardTypeMap<RootComponentType>, RootComponentType>

/**
 * Draft component to unify product card experience across different product formats (Econda, Algolia, PIM).
 * Figma design https://www.figma.com/design/vkkS2UvzF4LqIOXB4x5Xwy/RC-%2B-SAE-Web-Components?node-id=2672-20635&m=dev.
 * Follow https://jira.shop-apotheke.com/browse/WSAWA-6413.
 */
export const ProductCard = <RootComponentType extends ElementType = 'section'>(
  props: ProductCardProps<RootComponentType>,
): ReactElement<ProductCardProps<RootComponentType>, RootComponentType> => {
  const { slotProps, slots, ...restProps } = props

  const Root = slots?.root ?? 'section'
  const rootProps = useSlotProps({
    className: 'product-card',
    elementType: Root,
    externalForwardedProps: restProps,
    externalSlotProps: slotProps?.root,
    ownerState: undefined,
  })

  return <Root {...rootProps} />
}
