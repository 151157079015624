import { type FC } from 'react'

import {
  AD_BANNER_CATEGORY_WIDE,
  AD_BANNER_CATEGORY_WIDE_DIMENSIONS,
  AdServerBanner,
} from '../../../ad-server'
import { useDeviceDetectorContext } from '../../../device-detector'

export const StoryblokCategoryAdBannerWide: FC = () => {
  const { isTablet } = useDeviceDetectorContext()

  return (
    <AdServerBanner
      bannerTags={[AD_BANNER_CATEGORY_WIDE]}
      className="justify-center"
      dimensions={
        isTablet
          ? AD_BANNER_CATEGORY_WIDE_DIMENSIONS.SMALL
          : AD_BANNER_CATEGORY_WIDE_DIMENSIONS.LARGE
      }
      showSkeleton
    />
  )
}
