import { type FC } from 'react'

import {
  AD_BANNER_CATEGORY_SMALL_1,
  AD_BANNER_CATEGORY_SMALL_2,
  AD_BANNER_CATEGORY_SMALL_DIMENSIONS,
  AdServerBanner,
} from '../../../ad-server'
import { useDeviceDetectorContext } from '../../../device-detector'

export const StoryblokCategoryAdBannersSmall: FC = () => {
  const { isTablet } = useDeviceDetectorContext()

  const dimensions = isTablet
    ? AD_BANNER_CATEGORY_SMALL_DIMENSIONS.SMALL
    : AD_BANNER_CATEGORY_SMALL_DIMENSIONS.LARGE

  return (
    <div className="flex flex-col items-center justify-center gap-6 tablet:flex-row">
      <AdServerBanner
        bannerTags={[AD_BANNER_CATEGORY_SMALL_1]}
        dimensions={dimensions}
        showSkeleton
      />
      <AdServerBanner
        bannerTags={[AD_BANNER_CATEGORY_SMALL_2]}
        dimensions={dimensions}
        showSkeleton
      />
    </div>
  )
}
