'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'

import { useStoryblokStory } from '../../context/StoryblokStoryContext'
import { type StoryblokComponentProps } from '../../model'
import { type StoryblokAkeneoCategoryCrossSellType } from '../../model/storyblokTypes.generated'

import { StoryblokAkeneoCategoryCrossSellGrid } from './StoryblokAkeneoCategoryCrossSellGrid'
import { StoryblokAkeneoCategoryCrossSellPopularProductsSlider } from './StoryblokAkeneoCategoryCrossSellPopularProductsSlider'

/**
 * Used only on Akeneo pages and show widgets from the config
 */
export const StoryblokAkeneoCategoryCrossSell: FC<
  StoryblokComponentProps<StoryblokAkeneoCategoryCrossSellType>
> = ({ blok }) => {
  const story = useStoryblokStory()

  if (story.content.component !== 'AkeneoCategoryPage') {
    return null
  }

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-col gap-9 desktop:py-6"
    >
      <StoryblokAkeneoCategoryCrossSellGrid
        categoryCode={story.content.akeneoCategoryCode}
      />
      <StoryblokAkeneoCategoryCrossSellPopularProductsSlider
        categoryCode={story.content.akeneoCategoryCode}
      />
    </div>
  )
}
