import { type FC } from 'react'

type SeoHeadTitleProps = {
  title: string
}

export const SeoHeadTitleMetas: FC<SeoHeadTitleProps> = ({ title }) => {
  return (
    <>
      <title key="title">{title}</title>
      <meta content={title} key="og:title" property="og:title" />
      <meta content={title} key="twitter:title" property="twitter:title" />
    </>
  )
}
