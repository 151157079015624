import { type FC, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import { type CrossSellResponse } from '@redteclab/api/clients/retail-media-xsell-service'

import { Heading } from 'base-ui'

import {
  CrossSellWidgetMoreLink,
  CrossSellWidgetTitle,
  useCrossSellWidgetTitle,
} from '../../../cross-sell'
import { useGlobalConfigContext } from '../../../global-config'
import { urlResolverGetSearchPage } from '../../../url-handling'

type StoryblokCrossSellHeaderProps = {
  data?: CrossSellResponse
  widgetId: string
}

export const StoryblokCrossSellHeader: FC<StoryblokCrossSellHeaderProps> = ({
  data,
  widgetId,
}) => {
  const config = useGlobalConfigContext()

  const widgetTitle = useCrossSellWidgetTitle({
    data,
    widgetId,
  })

  const searchLink = useMemo(
    () =>
      data?.items
        ? urlResolverGetSearchPage(config, {
            queryParams: {
              // eslint-disable-next-line id-length
              q: data.items.map(({ id }) => id).join(' '),
              wid: widgetId,
            },
          })
        : undefined,
    [config, data?.items, widgetId],
  )

  return (
    <div className="mb-4 flex flex-col items-start justify-between gap-1 tablet:flex-row tablet:items-center">
      <CrossSellWidgetTitle<typeof Heading>
        level={2}
        size="l"
        slots={{ root: Heading }}
      >
        {widgetTitle}
      </CrossSellWidgetTitle>
      <CrossSellWidgetMoreLink href={searchLink}>
        <FormattedMessage id="general.label.ShowAllProducts" />
      </CrossSellWidgetMoreLink>
    </div>
  )
}
