'use client'

import { useEffect, useState } from 'react'

/**
 * When creating an anchor in a storyblok `RichText` field, the `id` is assigned
 * to a span element. This hook will find all span elements with an id and
 * return them.
 */
export const useStoryblokRichTextAnchors = (
  containerId: string,
): HTMLSpanElement[] => {
  const [anchors, setAnchors] = useState<HTMLSpanElement[]>([])

  useEffect(() => {
    const headingElements = [
      ...document.querySelectorAll<HTMLSpanElement>(`#${containerId} span[id]`),
    ]

    setAnchors(headingElements)
  }, [containerId])

  return anchors
}
