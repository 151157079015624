'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'

import {
  BrandIllustration,
  type BrandIllustrationName,
} from '../../../brand-illustration'
import { type StoryblokComponentProps } from '../../model'
import { type StoryblokBrandIllustrationType } from '../../model/storyblokTypes.generated'

/**
 * Brand illustration names are not stored in the JavaScript code to avoid
 * unneeded client-side bundle increase. Rely on datasource `design-brand-illustration-names`
 * https://app.storyblok.com/#/me/spaces/263064/datasources/485132.
 */
const isBranIllustrationName = (name: string): name is BrandIllustrationName =>
  Boolean(name as BrandIllustrationName)

export const StoryblokBrandIllustration: FC<
  StoryblokComponentProps<StoryblokBrandIllustrationType>
> = ({ blok }) => {
  const { name, size, type } = blok

  if (!isBranIllustrationName(name)) {
    return null
  }

  return (
    <BrandIllustration
      {...storyblokEditable(blok)}
      alt=""
      name={name}
      size={size}
      type={type}
    />
  )
}
