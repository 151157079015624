'use client'

import { type FC } from 'react'

import { useGlobalConfigContext } from '../../../global-config'
import { SHOP_ENV } from '../../../tenant'

const DEV_MESSAGE = 'Error rendering component'

/**
 * So we can see there's a storyblok component failing to render
 * This component will be rendered in non production environment only
 */
export const StoryblokComponentRendererDevErrorFallback: FC = () => {
  const { shopEnv } = useGlobalConfigContext()

  if (shopEnv === SHOP_ENV.PROD) {
    return null
  }

  return (
    <div className="border-b-dark-error-strong text-dark-error-strong">
      {DEV_MESSAGE}
    </div>
  )
}
