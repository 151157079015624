'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useMemo } from 'react'

/**
 * Custom hook to get the full path including query parameters.
 *
 * @returns {string} The current pathname combined with its query parameters.
 *
 * @example
 * // url in browser: https://www.example.com/search?q=example
 * const fullPath = useFullPath() // returns '/search?q=example'
 */
export const useFullPath = (): string => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  return useMemo(() => {
    if (!pathname) {
      return ''
    }

    // If searchParams is undefined or empty, return pathname
    if ([...searchParams].length === 0) {
      return pathname
    }

    // Serialize the search parameters into a query string
    const queryString = searchParams.toString()

    // Combine pathname and query string
    return `${pathname}?${queryString}`
  }, [pathname, searchParams])
}
