import { type FC } from 'react'
import { useIntl } from 'react-intl'

import {
  type AlgoliaRecord,
  algoliaRecordAccessorGetFormattedPricePerUnit,
} from '../../../algolia'

type CrossSellProductPriceProps = {
  product: AlgoliaRecord
}

export const StoryblokProductBatteryPrice: FC<CrossSellProductPriceProps> = ({
  product,
}) => {
  const intl = useIntl()

  return (
    <div className="flex min-h-[39px] flex-col">
      <span className="flex flex-col">
        <span className="min-h-[21px] whitespace-nowrap text-dark-primary-low line-through">
          {product.hide_discounts ? '' : product.listPriceFormatted}
        </span>
        <span className="whitespace-nowrap text-2xl font-bold leading-l text-dark-brand">
          {product.priceFormatted}
        </span>
      </span>
      <span className="text-s text-dark-primary-medium">
        {algoliaRecordAccessorGetFormattedPricePerUnit(product, intl)}
      </span>
    </div>
  )
}
