'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type FC, useEffect } from 'react'
import { useBoolean } from 'usehooks-ts'

import { Accordion, AccordionDetails, AccordionSummary } from 'base-ui'

import { useUrlHash } from '../../../generic-hooks'
import { type StoryblokComponentProps } from '../../model'
import { type StoryblokAccordionType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

export const StoryblokAccordion: FC<
  StoryblokComponentProps<StoryblokAccordionType>
> = ({ blok }) => {
  const [hash] = useUrlHash()

  const {
    setTrue: expand,
    setValue: setExpanded,
    value: expanded,
  } = useBoolean(blok.open ?? false)

  useEffect(() => {
    if (blok.anchor && hash === `#${blok.anchor}`) {
      expand()
    }
  }, [blok.anchor, expand, hash])

  return (
    <Accordion
      {...storyblokEditable(blok)}
      className={clsx(blok.anchor && 'scroll-mt-4')}
      expanded={expanded}
      id={blok.anchor === '' ? undefined : blok.anchor}
      onChange={(event, value) => {
        setExpanded(value)
      }}
    >
      <AccordionSummary>{blok.title}</AccordionSummary>
      <AccordionDetails>
        <StoryblokComponentsRenderer bloks={blok.content} />
      </AccordionDetails>
    </Accordion>
  )
}
