'use client'

import { type ComponentProps, type FC } from 'react'

import {
  type BrandIllustrationName,
  type BrandIllustrationSize,
  type BrandIllustrationType,
} from './brandIllustrationGetImage'
import { useBrandIllustrationImageProps } from './useBrandIllustrationImageProps'

type BrandIllustrationProps = ComponentProps<'img'> & {
  name: BrandIllustrationName
  size: BrandIllustrationSize
  type: BrandIllustrationType
}

export const BrandIllustration: FC<BrandIllustrationProps> = (props) => {
  const { alt, name, size, type, ...restProps } = props

  const imageProps = useBrandIllustrationImageProps({
    name,
    size,
    type,
  })

  return (
    /**
     * Brand illustration uses custom `srcSet` attribute while leveraging Next.js `getImageProps`
     * from {@link useBrandIllustrationImageProps} for other image properties
     */
    // eslint-disable-next-line @next/next/no-img-element
    <img {...restProps} {...imageProps} alt={alt} />
  )
}
