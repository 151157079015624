import {
  type StoryblokComponentType,
  StoryblokServerComponent,
} from '@storyblok/react/rsc'
import { type FC, Suspense } from 'react'

import { ErrorBoundary } from '../../../error-boundary'

import { StoryblokComponentRendererDevErrorFallback } from './StoryblokComponentRendererDevErrorFallback'

type StoryblokComponentsRendererProps = {
  bloks?: StoryblokComponentType<string>[]
}

/**
 * This is an entrypoint for Storyblok component rendering.
 *
 * To avoid full page error on single component rendering error, we wrap every component with `Suspense` and `ErrorBoundary`.
 *
 * If a component throws an error on the server, React will not abort the server render.
 * Instead, it will find the closest `<Suspense>` component above it and include its fallback
 * into the generated server HTML. The user will see a fallback at first.
 *
 * On the client, React will attempt to render the same component again.
 * If it errors on the client too, React will throw the error and display the closest error boundary.
 * However, if it does not error on the client, React will not display the error to the user
 * since the content was eventually displayed successfully.
 *
 * More info https://react.dev/reference/react/Suspense#providing-a-fallback-for-server-errors-and-client-only-content
 *
 * P.S. For simple components this wrapping could be an overhead, so makes sense to review this approach again later.
 */
export const StoryblokComponentsRenderer: FC<
  StoryblokComponentsRendererProps
> = ({ bloks }) => {
  return bloks?.map((blok) => {
    const { _uid: key } = blok

    return (
      <Suspense key={key}>
        <ErrorBoundary
          fallback={<StoryblokComponentRendererDevErrorFallback />}
        >
          <StoryblokServerComponent blok={blok} />
        </ErrorBoundary>
      </Suspense>
    )
  })
}
