'use client'

import { type FC, useEffect } from 'react'

import { useAdServerConfigurationContext } from '../ad-server'

import { type CmsPageProps } from './cmsPagesGetServerSideProps'

export const CmsLoadAdBanners: FC<{
  pageBanners: CmsPageProps['allAdBannersInStory']
}> = ({ pageBanners }) => {
  const { initBanners } = useAdServerConfigurationContext()

  useEffect(() => {
    initBanners(pageBanners)
  }, [initBanners, pageBanners])

  return null
}
