'use client'

import { type AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import { useRouter, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'

import { useDeviceDetectorContext } from '../../device-detector'

import { type OpenableControls } from './useOpenableControls'

/**
 * on mobile pushes new url (without the param controlling the open state) - so tapping back will close the modal
 * on desktop replace - to avoid situation where it's opened on back navigations.
 * reasoning https://jira.shop-apotheke.com/browse/WSAWA-5834
 */
const pushOrReplaceHistoryDependingOnDevice = ({
  currentUrl,
  isMobile,
  router,
}: {
  currentUrl: URL
  isMobile: boolean
  router: AppRouterInstance
}): void => {
  if (isMobile) {
    router.push(currentUrl.href, { scroll: false })

    return
  }

  router.replace(currentUrl.href, { scroll: false })
}

/**
 * openable controls when open state is controlled by a search param.
 */
export const useSearchParamDependentOpenableControls = ({
  paramName,
}: {
  paramName: string
}): OpenableControls => {
  const { isMobile } = useDeviceDetectorContext()
  const readonlySearchParams = useSearchParams()
  const router = useRouter()
  /**
   * isOpen is controlled through state because > next 13 there's no way to "shallow" push without
   * hard navigation.
   * So:
   * - to have instant open/close we use state
   * - to have a back button functionality and to sync with next router, we push via router once again
   * caveat:
   * since next 13 there is no "shallow pushing without refetching page data"
   * it is known that this will cause a page refetch, but it's the only way to keep the url in sync
   */
  const [isOpen, setIsOpen] = useState(
    readonlySearchParams.get(paramName) === 'true',
  )

  useEffect(() => {
    /**
     * resync state on router changes. E.g. navigated to other PDP, or param removed trhough router.push
     */
    const isOpenOnParam = readonlySearchParams.get(paramName) === 'true'
    setIsOpen(isOpenOnParam)
  }, [paramName, readonlySearchParams])

  const removeParam = (): void => {
    const currentUrl = new URL(window.location.href)
    currentUrl.searchParams.delete(paramName)
    // state for immediate ui update
    setIsOpen(false)

    // on close always replace on both devices, so on "back navigation" it's not opened.
    router.replace(currentUrl.href, { scroll: false })
  }

  const setParamToTrue = (): void => {
    const currentUrl = new URL(window.location.href)
    currentUrl.searchParams.set(paramName, 'true')
    setIsOpen(true)
    // on open push on mobile - so tapping back closes, replace on desktop
    pushOrReplaceHistoryDependingOnDevice({ currentUrl, isMobile, router })
  }

  return {
    handleClose: removeParam,
    handleOpen: setParamToTrue,
    handleToggle: isOpen ? removeParam : setParamToTrue,
    isOpen,
  }
}
