'use client'

import { useCallback, useEffect, useState } from 'react'

const getInitialHash = (): string =>
  typeof window === 'undefined' ? '' : window.location.hash

type UseHashType = [hash: string, setHash: (hash: string) => void]

export const useUrlHash = (): UseHashType => {
  const [hash, setHash] = useState(getInitialHash())

  useEffect(() => {
    const hashChangeHandler = (): void => {
      setHash(window.location.hash)
    }

    window.addEventListener('hashchange', hashChangeHandler)

    return (): void => {
      window.removeEventListener('hashchange', hashChangeHandler)
    }
  }, [])

  const setHashCallback = useCallback(
    (newHash: string) => {
      // Not using history API because https://html.spec.whatwg.org/multipage/nav-history-apis.html#delta-traverse p.3
      const { scrollX, scrollY } = window
      if (`#${newHash}` !== hash) {
        window.location.hash = newHash
        window.scrollTo(scrollX, scrollY)
      }
    },
    [hash],
  )

  return [hash, setHashCallback]
}
