import { cmsStoryblokSlugToPublicUrl } from '../cmsStoryblokSlugToPublicUrl'

import { StoryblokLinkType } from './StoryblokLinkType'
import { type StoryblokMultilinkType } from './storyblokTypes.generated'

/**
 * Retrieves the string url from the storyblok's native link type.
 * @remarks if story is linked by default the domain will be added unless the addTenantHostFromPathPrefix is set to false.
 *
 * examples:
 * in storyblok link with type "url" set to /foo/bar => /foo/bar
 * in storyblok link with type "story" set to linked story ch-de => www.redcare.ch/de
 * in storyblok link with type "story" set to linked story ch-de => {
 *     addTenantHostFromPathPrefix: false
 *   } => /de
 */
export const storyAccessorGetUrlFromMultiLink = (
  multiLink?: StoryblokMultilinkType,
  options?: {
    addTenantHostFromPathPrefix?: boolean
  },
): string | undefined => {
  if (!multiLink) {
    return undefined
  }

  switch (multiLink.linktype) {
    case StoryblokLinkType.story: {
      const fullSlug = multiLink.story?.full_slug

      if (!fullSlug) {
        return undefined
      }

      return cmsStoryblokSlugToPublicUrl(fullSlug, {
        addTenantHostFromPathPrefix: options?.addTenantHostFromPathPrefix,
      })
    }

    case StoryblokLinkType.url: {
      return multiLink.url
    }

    default: {
      return undefined
    }
  }
}
