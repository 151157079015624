'use client'

import { type ISbStoryData } from '@storyblok/react'
import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

import { type StoryblokPageContentType } from '../model/storyblokPageContentTypes'

const StoryblokStoryContext =
  createContext<ISbStoryData<StoryblokPageContentType> | null>(null)

type StoryblokStoryProviderProps = PropsWithChildren<{
  story: ISbStoryData<StoryblokPageContentType>
}>

export const StoryblokStoryProvider: FC<StoryblokStoryProviderProps> = ({
  children,
  story,
}) => {
  return (
    <StoryblokStoryContext.Provider value={story}>
      {children}
    </StoryblokStoryContext.Provider>
  )
}

export const useStoryblokStory = (): ISbStoryData<StoryblokPageContentType> => {
  const context = useContext(StoryblokStoryContext)

  if (context === null) {
    throw new Error('StoryblokStoryContext must be used within a CmsPage')
  }

  return context
}
