'use client'

import Image, { getImageProps, type ImageProps } from 'next/image'
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  type ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { useExperimentalAddStaticFileHost } from '../../../experiments/new-cdn-experiment/useExperimentalAddStaticFileHost'
import {
  getImageUrlWithWebpExtension,
  IMAGE_PRODUCT_FALLBACK_SRC,
  IMAGE_PRODUCT_PRESCRIPTION_FALLBACK_SRC,
  useCdnHostedImageLoader,
} from '../../../image'

type ProductPictureProps = ComponentPropsWithoutRef<'picture'> & {
  /**
   * Custom fallback image
   */
  fallbackSrc?: string

  /**
   * Props for Next.js `Image` component. If product image is not provided
   * do a fallback depending on prescription `flag` or `fallbackSrc` prop
   */
  imageProps: Omit<ImageProps, 'loader' | 'src'> & { src?: string }

  /**
   * Flag prescription product
   */
  prescription?: boolean
}

/**
 * The ProductPicture component displays product image using CDN source.
 * It has fallback mechanism for broken or failed images. Provide different
 * fallbacks for prescription and non-prescription products.
 *
 * Solution is inspired by https://stackoverflow.com/a/70544058.
 *
 */
/* eslint-disable max-statements */
export const ProductPicture = forwardRef<
  HTMLPictureElement,
  ProductPictureProps
>(function ProductPicture(props, ref) {
  const { fallbackSrc, imageProps, prescription, ...restProps } = props
  const { addStaticCdnHostToUrl } = useExperimentalAddStaticFileHost()

  const {
    alt,
    onError: onErrorProp,
    onLoad: onLoadProp,
    src,
    ...restImageProps
  } = imageProps

  const loader = useCdnHostedImageLoader()

  const [error, setError] = useState(false)

  // Reset fallback state if `src` changes
  useEffect(() => {
    if (src) {
      setError(false)
    }
  }, [src])

  const onError: Required<ImageProps>['onError'] = useCallback(
    (event) => {
      setError(true)
      onErrorProp?.(event)
    },
    [onErrorProp],
  )

  const onLoad: Required<ImageProps>['onLoad'] = useCallback(
    (event) => {
      if (
        event.target instanceof HTMLImageElement &&
        // Broken image
        event.target.naturalWidth === 0
      ) {
        setError(true)
      }
      onLoadProp?.(event)
    },
    [onLoadProp],
  )

  let image: ReactElement | null = null

  if (error) {
    image = (
      <Image
        alt=""
        {...restImageProps}
        src={addStaticCdnHostToUrl(IMAGE_PRODUCT_FALLBACK_SRC)}
        unoptimized
      />
    )
  } else if (src) {
    const { props: webpImageProps } = getImageProps({
      ...restImageProps,
      alt,
      loader,
      src: getImageUrlWithWebpExtension(src),
    })

    image = (
      <>
        <source srcSet={webpImageProps.srcSet} type="image/webp" />
        <Image alt={alt} {...restImageProps} loader={loader} src={src} />
      </>
    )
  } else {
    const fallback =
      fallbackSrc ??
      (prescription
        ? addStaticCdnHostToUrl(IMAGE_PRODUCT_PRESCRIPTION_FALLBACK_SRC)
        : addStaticCdnHostToUrl(IMAGE_PRODUCT_FALLBACK_SRC))

    image = <Image alt="" {...restImageProps} src={fallback} unoptimized />
  }

  return (
    <picture {...restProps} onError={onError} onLoad={onLoad} ref={ref}>
      {image}
    </picture>
  )
})
