'use client'

import { type FC, type MouseEvent, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Button, FormControl, FormLabel, Input, Notification } from 'base-ui'

import { useNowApiV4SwrGetBasicCheckMutation } from '../../../now'
import { type StoryblokComponentProps } from '../../model'
import { type StoryblokNowLpZipCodeElegibilityCheckerType } from '../../model/storyblokTypes.generated'

type StoryblokNowLpZipCodeElegibilityCheckerProps =
  StoryblokComponentProps<StoryblokNowLpZipCodeElegibilityCheckerType>

export const StoryblokNowLpZipCodeChecker: FC<
  StoryblokNowLpZipCodeElegibilityCheckerProps
> = () => {
  const [zipCode, setZipCode] = useState('')
  const [isEligible, setIsEligible] = useState<boolean | null>(null)

  const { trigger } = useNowApiV4SwrGetBasicCheckMutation({
    onSuccess: (data) => {
      setIsEligible(data.nowEligible ?? false)
    },
  })

  const handleSubmit = async (): Promise<void> => {
    try {
      const response = await trigger({
        zipCode,
      })
      setIsEligible(response.nowEligible ?? false)
    } catch {
      setIsEligible(false)
    }
  }

  return (
    <>
      <div className="mt-2.5 flex flex-wrap items-center gap-2">
        <FormControl className="mobile-sm:w-full desktop:w-auto">
          <FormLabel>
            <FormattedMessage id="general.label.now.zipcode" />
          </FormLabel>
          <Input
            id="zipCode"
            onChange={(event) => {
              setZipCode(event.target.value)
            }}
            value={zipCode}
          />
        </FormControl>
        <Button
          className="mobile-sm:w-full desktop:w-auto"
          onClick={(event: MouseEvent): void => {
            event.preventDefault()
            void handleSubmit()
          }}
          type="button"
        >
          <FormattedMessage id="now.landing.checkEligibility" />
        </Button>
      </div>

      <div>
        {isEligible === false && (
          <Notification severity="error">
            <FormattedMessage id="now.landing.zipNotEligible" />
          </Notification>
        )}

        {isEligible === true && (
          <Notification icon severity="success">
            <FormattedMessage id="now.landing.zipEligible" />
          </Notification>
        )}
      </div>
    </>
  )
}
