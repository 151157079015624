'use client'

import { useEffect, useRef, useState } from 'react'

/**
 * sometimes it's needed to be ensured that something will run on next render.
 * e.g. you have hidden inputs in form that is derived from state, but on submit you update the state,
 * and want to resubmit.
 *
 * This hook allows you to schedule a function to run on next render.
 */
export const useRerenderAndExecute = () => {
  const callbackRef = useRef<(() => void) | null>(null)

  const [trigger, setTrigger] = useState(false)

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current()
      callbackRef.current = null
    }
  }, [trigger])

  return (callback: () => void): void => {
    callbackRef.current = callback
    setTrigger((prev) => !prev)
  }
}
