'use client'
import { type FC, useEffect } from 'react'

import { useExperimentsContext } from '../../../experiments/components/ExperimentsContext'

export const StoryblokOptimizelyExperimentActivation: FC<{
  experimentName: string
}> = ({ experimentName }) => {
  const { addExperimentKeysForActivation, experiments } =
    useExperimentsContext()

  useEffect(() => {
    if (experimentName) {
      addExperimentKeysForActivation(experimentName)
    }
  }, [addExperimentKeysForActivation, experimentName, experiments])

  return null
}
