/**
 * when we use "link" block it contains a linktype property.
 * this one represents the possible values.
 */
export const StoryblokLinkType = {
  story: 'story',
  url: 'url',
} as const

export type StoryblokLinkType =
  (typeof StoryblokLinkType)[keyof typeof StoryblokLinkType]
