import Image from 'next/image'
import NextLink from 'next/link'
import { type FC } from 'react'

import { type AlgoliaRecord } from '../../../algolia'
import { useExperimentalAddStaticFileHost } from '../../../experiments/new-cdn-experiment/useExperimentalAddStaticFileHost'
import {
  IMAGE_PRODUCT_FALLBACK_SRC,
  useCdnHostedImageLoader,
} from '../../../image'
import { urlGetRelativeUrl } from '../../../url-handling'

import { StoryblokProductBatteryProductImageDiscount } from './StoryblokProductBatteryImageDiscount'

type ProductBatteryImageProps = {
  product: AlgoliaRecord
}

const MAX_IMAGE_SIZE = 160

export const StoryblokProductBatteryProductImage: FC<
  ProductBatteryImageProps
> = ({ product }) => {
  const loader = useCdnHostedImageLoader()
  const { addStaticCdnHostToUrl } = useExperimentalAddStaticFileHost()

  return (
    <NextLink
      className="relative px-7 py-3.5 after:absolute after:inset-0 after:bg-gradient-radial after:from-transparent after:via-transparent after:to-[var(--background-light-primary-medium)] tablet:p-3.5"
      data-qa-id={`form-product-slider-${product.id}-image-button`}
      href={urlGetRelativeUrl(product.deeplink)}
    >
      <div className="flex aspect-square max-h-[130px] w-full items-center justify-center">
        <Image
          alt={product.productName}
          className="max-h-full w-auto max-w-full"
          data-analytics="item-image"
          height={MAX_IMAGE_SIZE}
          loader={loader}
          src={
            product.image ?? addStaticCdnHostToUrl(IMAGE_PRODUCT_FALLBACK_SRC)
          }
          unoptimized={!product.image}
          width={MAX_IMAGE_SIZE}
        />
      </div>
      <StoryblokProductBatteryProductImageDiscount product={product} />
    </NextLink>
  )
}
