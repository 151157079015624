'use client'

import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Dialog, DialogContent, DialogTitle } from 'base-ui'

export const ProductPrescriptionDialogMedicalDevice: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle onClose={onClose}>
        <FormattedMessage id="medical_device.modal.title" />
      </DialogTitle>
      <DialogContent className="flex flex-col">
        <FormattedMessage id="medical_device.modal.text" />
      </DialogContent>
    </Dialog>
  )
}
