import { clsx } from 'clsx'
import { type FC } from 'react'

import { Heading } from 'base-ui'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokHeadingV2Type } from '../../model/storyblokTypes.generated'

type StoryblokHeadingV2Props = StoryblokComponentProps<StoryblokHeadingV2Type>

/**
 * Provide explicit mapping of Storyblok generated {@link StoryblokHeadingV2Type#level}
 * to {@link Heading#level} from `base-ui`. Storyblok generates only string types.
 */
const levelMap = {
  '1': 1,
  '2': 2,
  '3': 3,
  '4': 4,
  '5': 5,
  '6': 6,
} as const

export const StoryblokHeadingV2: FC<StoryblokHeadingV2Props> = ({ blok }) => {
  const { alignment, level, text, ...rest } = blok

  return (
    <Heading
      className={clsx(
        alignment === 'left' && 'text-start',
        alignment === 'right' && 'text-end',
        alignment === 'center' && 'text-center',
      )}
      level={levelMap[level]}
      {...rest}
    >
      {text}
    </Heading>
  )
}
