'use client'

import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Dialog, DialogContent, DialogTitle } from 'base-ui'

import { ProductPrescriptionDialogDynamicContent } from './ProductPrescriptionDialogDynamicContent'

export const ProductPrescriptionDialog: FC<{
  isOpen: boolean
  onClose: () => void
}> = ({ isOpen, onClose }) => {
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogTitle onClose={onClose}>
        <FormattedMessage id="rezeptBestellung.modal.title" />
      </DialogTitle>
      <DialogContent>
        <ProductPrescriptionDialogDynamicContent />
      </DialogContent>
    </Dialog>
  )
}
