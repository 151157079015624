import { type FC } from 'react'

import { type AlgoliaRecord } from '../../../algolia'
import { priceGetFormattedPercentageDiscount } from '../../../price'

type Props = {
  product: AlgoliaRecord
}

export const StoryblokProductBatteryProductImageDiscount: FC<Props> = ({
  product,
}) => {
  const discount =
    product.discountInPercent > 0 &&
    priceGetFormattedPercentageDiscount(product.discountInPercent)

  if (!discount) {
    return null
  }

  return (
    <span className="absolute right-2.5 top-3.5 block aspect-square w-12 rounded-full bg-light-tertiary text-center font-mono font-medium leading-[48px] text-dark-primary-max">
      {discount}
    </span>
  )
}
