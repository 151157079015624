import { type FC } from 'react'

import {
  CROSS_SELL_AKENEO_CATEGORY,
  CrossSellGrid,
  CrossSellWidgetContainer,
  useCrossSellConfigContext,
  useCrossSellIntersection,
  useSwrCrossSellHorizontalSlider,
} from '../../../cross-sell'
import { StoryblokCrossSellHeader } from '../StoryblokCrossSellHeader'

type StoryblokAkeneoCategoryCrossSellGridProps = {
  categoryCode: string
}

export const StoryblokAkeneoCategoryCrossSellGrid: FC<
  StoryblokAkeneoCategoryCrossSellGridProps
> = ({ categoryCode }) => {
  const { widgets } = useCrossSellConfigContext()
  const widgetId = widgets[CROSS_SELL_AKENEO_CATEGORY]?.id

  const { handleIntersection, intersected } = useCrossSellIntersection()

  const { data, error } = useSwrCrossSellHorizontalSlider(
    intersected && widgetId
      ? {
          categoryId: categoryCode,
          visibleItemCount: 8,
          widgetId,
        }
      : undefined,
  )

  if (!widgetId || data?.items.length === 0 || error) {
    return null
  }

  return (
    <CrossSellWidgetContainer
      className="w-full"
      onIntersect={handleIntersection}
      widgetData={data}
      widgetId={widgetId}
    >
      <StoryblokCrossSellHeader data={data} widgetId={String(widgetId)} />
      <CrossSellGrid products={data?.items} />
    </CrossSellWidgetContainer>
  )
}
