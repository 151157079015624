'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import NextLink from 'next/link'
import { type FC } from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { IconArrowRight } from '@redteclab/icons'

import { Button, Heading, Link, Rating } from 'base-ui'

import { useGlobalConfigContext } from '../../../global-config'
import { ProductPicture } from '../../../product'
import { TosSuperscriptHowProductRatingCalculated } from '../../../tos-superscripts'
import { urlNormalizeUri } from '../../../url-handling'
import { type StoryblokComponentProps } from '../../model'
import { type StoryblokProductRatingsType } from '../../model/storyblokTypes.generated'
import { useStoryblokServerSideData } from '../../storyblok-server-side-data/context/storyblokServerSideDataContext'

import styles from './StoryblokProductRatings.module.css'

export const StoryblokProductRatings: FC<
  StoryblokComponentProps<StoryblokProductRatingsType>
> = ({ blok }) => {
  const { locale } = useGlobalConfigContext()

  const { data } = useStoryblokServerSideData(blok)

  if (!data || data.productRatings.length === 0) {
    return null
  }

  return (
    <div {...storyblokEditable(blok)}>
      <Heading className="mb-4 tablet:mb-6" level={2} size="l">
        <FormattedMessage id="ratingOverview.headline" />
      </Heading>
      <ul className="grid grid-cols-2 gap-2 tablet:gap-4">
        {data.productRatings.map(({ product, review }) => (
          <li className="flex flex-col rounded-2xl shadow-m" key={product.upid}>
            <div className="relative">
              <div className="relative py-2 after:absolute after:inset-0 after:rounded-t-2xl after:bg-gradient-radial after:from-transparent after:via-transparent after:to-[var(--background-light-primary-medium)]">
                <ProductPicture
                  imageProps={{
                    alt: '',
                    className: styles['storyblok-product-ratings__image'],
                    height: 150,
                    src: product.images?.at(0),
                    width: 150,
                  }}
                />
              </div>
              <Link<typeof NextLink>
                className="mb-2 mt-4 line-clamp-3 px-4 font-medium no-underline tablet:px-6"
                color="inherit"
                href={urlNormalizeUri({
                  locale,
                  uri: `${product.canonical_url}#user-reviews-section`,
                })}
                overlay
                slots={{
                  root: NextLink,
                }}
              >
                {product.name}
              </Link>
            </div>
            <div className="flex flex-1 flex-col gap-4 p-4 pt-0 tablet:p-6 tablet:pt-0">
              <div className="flex">
                <Rating
                  className={styles['storyblok-product-ratings__rating']}
                  readOnly
                  value={review.rating}
                />
                <TosSuperscriptHowProductRatingCalculated className="top-1" />
              </div>
              <span className="text-xs font-medium">
                {review.customer.author}{' '}
                <FormattedMessage id="userReview.label.onDate" />{' '}
                <FormattedDate value={review.submissionDate} />
              </span>
              <span className="-mb-4 font-medium">{review.title}</span>
              <p className="line-clamp-3 flex-1 text-dark-primary-strong">
                {review.message}
              </p>
              <Button<typeof NextLink>
                className={styles['storyblok-product-ratings__button']}
                endIcon={<IconArrowRight />}
                href={urlNormalizeUri({
                  locale,
                  uri: `${product.canonical_url}#user-reviews-section`,
                })}
                size="small"
                slots={{
                  root: NextLink,
                }}
                variant="text"
              >
                <FormattedMessage id="general.label.toRating" />
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
