export const CROSS_SELL_PDP_BUY_TOGETHER_WITH =
  'CROSS_SELL_PDP_BUY_TOGETHER_WITH'
export const CROSS_SELL_PDP_CENTER_SLIDER = 'CROSS_SELL_PDP_CENTER_SLIDER'
export const CROSS_SELL_PDP_CENTER_SLIDER_GGLP_FIRST_IMPRESSION =
  'CROSS_SELL_PDP_CENTER_SLIDER_GGLP_FIRST_IMPRESSION'
export const CROSS_SELL_PDP_OUT_OF_STOCK_CENTER_SLIDER =
  'CROSS_SELL_PDP_OUT_OF_STOCK_CENTER_SLIDER'
export const CROSS_SELL_PDP_RX_CENTER_SLIDER = 'CROSS_SELL_PDP_RX_CENTER_SLIDER'
export const CROSS_SELL_PDP_SELLER_INFORMATION =
  'CROSS_SELL_PDP_SELLER_INFORMATION'
export const CROSS_SELL_MY_ACCOUNT_OVERVIEW = 'CROSS_SELL_MY_ACCOUNT_OVERVIEW'
export const CROSS_SELL_OWN_BRANDS_MY_ACCOUNT_OVERVIEW =
  'CROSS_SELL_OWN_BRANDS_MY_ACCOUNT_OVERVIEW'
export const CROSS_SELL_MOST_SOLD_PRODUCTS = 'CROSS_SELL_MOST_SOLD_PRODUCTS'
export const CROSS_SELL_TOP_SELLER_OWN_BRAND = 'CROSS_SELL_TOP_SELLER_OWN_BRAND'
export const CROSS_SELL_PRODUCTS_OF_THE_MONTH =
  'CROSS_SELL_PRODUCTS_OF_THE_MONTH'
export const CROSS_SELL_SECRET_TIPS = 'CROSS_SELL_SECRET_TIPS'
export const CROSS_SELL_ADD_TO_CART_TOP = 'CROSS_SELL_ADD_TO_CART_TOP'
export const CROSS_SELL_ADD_TO_CART_CENTER = 'CROSS_SELL_ADD_TO_CART_CENTER'
export const CROSS_SELL_ADD_TO_CART_BOTTOM = 'CROSS_SELL_ADD_TO_CART_BOTTOM'
export const CROSS_SELL_AKENEO_CATEGORY = 'CROSS_SELL_AKENEO_CATEGORY'
export const CROSS_SELL_AKENEO_CATEGORY_POPULAR_PRODUCTS =
  'CROSS_SELL_AKENEO_CATEGORY_POPULAR_PRODUCTS'

export type CrossSellWidgetType =
  | typeof CROSS_SELL_MY_ACCOUNT_OVERVIEW
  | typeof CROSS_SELL_OWN_BRANDS_MY_ACCOUNT_OVERVIEW
  | typeof CROSS_SELL_PDP_BUY_TOGETHER_WITH
  | typeof CROSS_SELL_PDP_CENTER_SLIDER
  | typeof CROSS_SELL_PDP_CENTER_SLIDER_GGLP_FIRST_IMPRESSION
  | typeof CROSS_SELL_PDP_OUT_OF_STOCK_CENTER_SLIDER
  | typeof CROSS_SELL_PDP_RX_CENTER_SLIDER
  | typeof CROSS_SELL_PDP_SELLER_INFORMATION
  | typeof CROSS_SELL_MOST_SOLD_PRODUCTS
  | typeof CROSS_SELL_TOP_SELLER_OWN_BRAND
  | typeof CROSS_SELL_PRODUCTS_OF_THE_MONTH
  | typeof CROSS_SELL_SECRET_TIPS
  | typeof CROSS_SELL_ADD_TO_CART_TOP
  | typeof CROSS_SELL_ADD_TO_CART_CENTER
  | typeof CROSS_SELL_ADD_TO_CART_BOTTOM
  | typeof CROSS_SELL_AKENEO_CATEGORY
  | typeof CROSS_SELL_AKENEO_CATEGORY_POPULAR_PRODUCTS
