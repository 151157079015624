'use client'

import { useEffect, useState } from 'react'

export const useIntersectionObserversOnAnchors = (
  anchors: Element[],
): string => {
  const [activeId, setActiveId] = useState<string>('')

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          const currentId = entry.target.id

          if (entry.isIntersecting) {
            setActiveId(currentId)
          }
        }
      },
      { rootMargin: '0px 0px -60% 0px', threshold: 0.1 },
    )

    for (const anchor of anchors) {
      intersectionObserver.observe(anchor)
    }

    return (): void => {
      for (const anchor of anchors) {
        intersectionObserver.unobserve(anchor)
      }
    }
  }, [activeId, anchors])

  return activeId
}
