import { type FC } from 'react'

import { type AlgoliaRecord } from '../../../algolia'
import { ProductCard } from '../../../product'

import { StoryblokProductBatteryName } from './StoryblokProductBatteryName'
import { StoryblokProductBatteryPrice } from './StoryblokProductBatteryPrice'
import { StoryblokProductBatteryProductImage } from './StoryblokProductBatteryProductImage'
import { StoryblokProductBatteryQuantity } from './StoryblokProductBatteryQuantity'

type Props = {
  product: AlgoliaRecord
}

export const StoryblokProductBatteryItem: FC<Props> = ({ product }) => {
  return (
    <ProductCard
      className="mb-6 flex h-full flex-1 flex-col overflow-hidden"
      data-product-id={product.objectID}
    >
      <StoryblokProductBatteryProductImage product={product} />
      <div className="flex flex-1 flex-col justify-between gap-1 px-2 py-2.5">
        <StoryblokProductBatteryName product={product} />
        <div className="flex flex-col gap-1">
          <StoryblokProductBatteryQuantity product={product} />
          <StoryblokProductBatteryPrice product={product} />
        </div>
      </div>
    </ProductCard>
  )
}
