'use client'

import { type FC, type ReactNode } from 'react'

import { cmsGetServerStoryblokApi } from '../../cmsGetServerStoryblokApi'

export const StoryblokProvider: FC<{
  children: Awaited<ReactNode>
}> = ({ children }) => {
  cmsGetServerStoryblokApi()

  return children
}
