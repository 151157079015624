'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconArrowLeft } from '@redteclab/icons'

import { Button } from 'base-ui'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokAdvicePageType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

import { useBackLinkUrl } from './useBackLinkUrl'

type StoryblokAdvicePageProps = StoryblokComponentProps<StoryblokAdvicePageType>

export const StoryblokAdvicePage: FC<StoryblokAdvicePageProps> = ({ blok }) => {
  const { body, title } = blok

  const goBackUrl = useBackLinkUrl()

  return (
    <div {...storyblokEditable(blok)}>
      <div className="flex w-full justify-center bg-light-now py-6">
        <div className="mx-auto w-full max-w-5xl">
          <Button<'a'>
            href={goBackUrl}
            slots={{ root: 'a' }}
            startIcon={<IconArrowLeft />}
            variant="text"
          >
            <FormattedMessage id="general.link.label.back" />
          </Button>
          <h1 className="px-6 text-3xl">{title}</h1>
        </div>
      </div>
      <StoryblokComponentsRenderer bloks={body} />
    </div>
  )
}
