import { type FC } from 'react'

import {
  CROSS_SELL_AKENEO_CATEGORY_POPULAR_PRODUCTS,
  CrossSellHorizontalSlider,
  CrossSellWidgetContainer,
  useCrossSellConfigContext,
  useCrossSellIntersection,
  useSwrCrossSellHorizontalSlider,
} from '../../../cross-sell'
import { StoryblokCrossSellHeader } from '../StoryblokCrossSellHeader'

type StoryblokAkeneoCategoryCrossSellPopularProductsSliderProps = {
  categoryCode: string
}

const ITEMS_PER_PAGE = 4

export const StoryblokAkeneoCategoryCrossSellPopularProductsSlider: FC<
  StoryblokAkeneoCategoryCrossSellPopularProductsSliderProps
> = ({ categoryCode }) => {
  const { widgets } = useCrossSellConfigContext()
  const widgetId = widgets[CROSS_SELL_AKENEO_CATEGORY_POPULAR_PRODUCTS]?.id

  const { handleIntersection, intersected } = useCrossSellIntersection()

  const { data, error } = useSwrCrossSellHorizontalSlider(
    intersected && widgetId
      ? {
          categoryId: categoryCode,
          productId: categoryCode,
          visibleItemCount: ITEMS_PER_PAGE,
          widgetId,
        }
      : undefined,
  )

  if (!widgetId || data?.items.length === 0 || error) {
    return null
  }

  return (
    <CrossSellWidgetContainer
      onIntersect={handleIntersection}
      widgetData={data}
      widgetId={widgetId}
      widgetType={CROSS_SELL_AKENEO_CATEGORY_POPULAR_PRODUCTS}
    >
      <StoryblokCrossSellHeader data={data} widgetId={String(widgetId)} />
      <CrossSellHorizontalSlider
        products={data?.items}
        productsPerPage={ITEMS_PER_PAGE}
      />
    </CrossSellWidgetContainer>
  )
}
