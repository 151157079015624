'use client'

import { useCallback, useState } from 'react'

type CopyText = {
  copied: boolean
  copyText: () => Promise<void>
}

/**
 * Default timeout to show that the text is copied
 */
const TIMOUT_TO_CHANGE_COPIED_TEXT = 3000

/**
 * responsible for controlling copying text
 *
 * @example
 * const { copied, copyText } = useCopyText(urlToBeCopied)
 *
 * <button onClick={() => { void copyText() }} type="button">
 *   <FormattedMessage id="socialModal.button.copyLink" />
 * </button>
 */
export const useCopyText = (
  textToBeCopied: string,
  /**
   * If it is 0, there will be no timeout
   */
  timeoutToChangeCopiedText = TIMOUT_TO_CHANGE_COPIED_TEXT,
): CopyText => {
  const [copied, setCopied] = useState(false)

  const copyText = useCallback(async () => {
    // eslint-disable-next-line compat/compat,@typescript-eslint/no-unnecessary-condition -- Clipboard API is not supported in old browsers
    await window.navigator.clipboard?.writeText(textToBeCopied)

    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, timeoutToChangeCopiedText)
  }, [textToBeCopied, timeoutToChangeCopiedText])

  return {
    copied,
    copyText,
  }
}
