import { storyblokEditable } from '@storyblok/react/rsc'
import { clsx } from 'clsx'
import { type FC } from 'react'

import { type StoryblokComponentProps } from '../../model'
import { type StoryblokAkeneoCategoryPageType } from '../../model/storyblokTypes.generated'
import { StoryblokComponentsRenderer } from '../StoryblokComponentsRenderer'

export const StoryblokAkeneoCategoryPage: FC<
  StoryblokComponentProps<StoryblokAkeneoCategoryPageType>
> = ({ blok }) => {
  const { body, variant } = blok

  return (
    <main
      {...storyblokEditable(blok)}
      className={clsx(
        'grid grid-cols-1',
        variant === 'content' && 'gap-9 py-4 desktop:py-6',
      )}
    >
      <StoryblokComponentsRenderer bloks={body} />
    </main>
  )
}
