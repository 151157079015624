/**
 * When using meta tags providing a key will allow Next to dedupe them.
 * in some part rendered:
 * <meta name='robots' key='robots'>
 * in som other part rendered:
 * <meta name='robots' key='robots'>
 *   Next will only render the last one.
 *
 * If rendered without key, Next will render both.
 *
 * WARNING: this will not work with react 19+ native meta tags as it does not dedupe in any way,
 * and it needs to be controlled manually.
 */

export const SEO_META_DEDUPE_KEY_ROBOTS = 'robots'
