import { cmsStoryblokSlugToPublicUrl } from '../../cmsStoryblokSlugToPublicUrl'
import { useStoryblokStory } from '../../context/StoryblokStoryContext'

/**
 * Cut last segment after slash (`/`) to get one level up path.
 *
 * @example
 * category/sub-category -> category
 * example/path/end-slug -> example/path
 */
const getParentPath = (path: string): string => {
  const lastSlashIndex = path.lastIndexOf('/')

  if (lastSlashIndex === -1) {
    return path
  }

  return path.slice(0, lastSlashIndex)
}

export const useBackLinkUrl = (): string => {
  const story = useStoryblokStory()

  return cmsStoryblokSlugToPublicUrl(getParentPath(story.full_slug))
}
