import { type PolymorphicComponent } from '@mui/base'
import { clsx } from 'clsx'
import { type ElementType } from 'react'

import { Skeleton } from 'base-ui'

type CrossSellHorizontalSliderWidgetTitleOwnProps = {
  slots?: {
    root?: ElementType
  }
}

interface CrossSellHorizontalSliderWidgetTitleTypeMap<
  RootComponentType extends ElementType = 'h2',
> {
  defaultComponent: RootComponentType
  props: CrossSellHorizontalSliderWidgetTitleOwnProps
}

export const CrossSellWidgetTitle: PolymorphicComponent<
  CrossSellHorizontalSliderWidgetTitleTypeMap
> = ({ children, slots, ...restProps }) => {
  const Root = slots?.root ?? 'h2'

  if (!children) {
    return (
      <Skeleton
        className={clsx(
          'cross-sell-widget-title_skeleton',
          restProps.className,
        )}
        {...restProps}
      />
    )
  }

  return <Root {...restProps}>{children}</Root>
}
