import { type FC } from 'react'

import { type AlgoliaRecord } from '../../../algolia'

type CrossSellProductQuantityProps = {
  product: AlgoliaRecord
}

export const StoryblokProductBatteryQuantity: FC<
  CrossSellProductQuantityProps
> = ({ product }) => {
  return (
    <span className="line-clamp-2 min-h-[42px] text-dark-primary-max">
      {[`${product.unitQuantityString} ${product.unit}`, product.pharmaForm]
        .filter(Boolean)
        .join(' | ')}
    </span>
  )
}
