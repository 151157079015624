import { apmCaptureError } from '../apm'

import { type DEPLOYMENT_STAGE } from './DEPLOYMENT_STAGE'
import { ProcessEnvError } from './ProcessEnvError'

export const processEnvAccessorIsDevelopment = (): boolean =>
  process.env.NODE_ENV === 'development'

export const processEnvAccessorGetBullyApiUrlForClient = (): string => {
  const bullyApiPath = process.env.BULLY_API_URL_FOR_CLIENT

  if (!bullyApiPath) {
    throw new Error('missing_env_BULLY_API_URL_FOR_CLIENT')
  }

  return bullyApiPath
}

export const processEnvAccessorGetBullyApiUrlForServer = (): string => {
  const bullyApiHost = process.env.BULLY_API_URL_FOR_SERVER

  if (!bullyApiHost) {
    throw new Error('missing_env_BULLY_API_URL_FOR_SERVER')
  }

  return bullyApiHost
}

export const processEnvAccessorGetDeploymentStage =
  (): `${DEPLOYMENT_STAGE}` => {
    const deploymentStage = process.env.DEPLOYMENT_STAGE

    if (!deploymentStage) {
      throw new Error('missing_env_DEPLOYMENT_STAGE')
    }

    return deploymentStage
  }

export const processEnvAccessorGetRetailMediaAdServerWidgetUrl = (): string => {
  return process.env.RETAIL_MEDIA_AD_SERVER_WIDGET_URL
}

export const processEnvAccessorGetAlgoliaApiKey = (): string => {
  const value = process.env.ALGOLIA_API_KEY
  if (!value) {
    apmCaptureError(new ProcessEnvError('ALGOLIA_API_KEY'))

    return ''
  }

  return value
}

export const processEnvAccessorGetAlgoliaApplicationId = (): string => {
  const value = process.env.ALGOLIA_APPLICATION_ID
  if (!value) {
    apmCaptureError(new ProcessEnvError('ALGOLIA_APPLICATION_ID'))

    return ''
  }

  return value
}

export const processEnvAccessorGetCrossSellWidgetsApiUrl = (): string => {
  const value = process.env.CROSS_SELL_WIDGETS_API_URL

  if (!value) {
    throw new Error('CROSS_SELL_WIDGETS_API_URL')
  }

  return value
}

export const processEnvAccessorGetStoryblokAccessToken = ():
  | string
  | undefined => {
  return process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN
}

export const processEnvAccessorGetStoryblokCacheServiceUrl = ():
  | string
  | undefined => {
  return process.env.STORYBLOK_CACHE_SERVICE_URL
}

export const processEnvAccessorGetStoryblokPreviewSecret = ():
  | string
  | undefined => {
  return process.env.STORYBLOK_PREVIEW_SECRET
}

export const processEnvAccessorGetRetailMediaServiceApiUrl = (): string => {
  const value = process.env.RETAIL_MEDIA_SERVICE_API_URL

  if (!value) {
    throw new Error('RETAIL_MEDIA_SERVICE_API_URL')
  }

  return value
}

export const processEnvAccessorGetRetailMediaServiceGlooApiUrl = (): string => {
  const value = process.env.RETAIL_MEDIA_SERVICE_GLOO_API_URL

  if (!value) {
    throw new Error('RETAIL_MEDIA_SERVICE_GLOO_API_URL')
  }

  return value
}
