import { useMemo } from 'react'

import { useExpaContext } from '../../expa'
import {
  type StoryblokCrossSellGridType,
  type StoryblokCrossSellSliderType,
} from '../model/storyblokTypes.generated'

const getExpaWidgetId = (
  expaWidgetIds: string,
  expa: string,
): string | undefined => {
  for (const row of expaWidgetIds.split('\n')) {
    const [key, value] = row.split('=')

    if (key === expa && value) {
      return value.trim()
    }
  }

  return undefined
}

/**
 * Returns best fit widget ID for Cross-Sell widget in Storyblok
 */
export const useStoryblokCrossSellWidgetId = (
  blok: StoryblokCrossSellGridType | StoryblokCrossSellSliderType,
): string => {
  const expa = useExpaContext()

  const {
    /**
     * New line separated pairs of values "expa=widget_id".
     * Example:
     * gglp=544
     * ide=317
     */
    expaWidgetIds,

    /**
     * Main widget ID field
     */
    widgetId,
  } = blok

  return useMemo(() => {
    if (expa && typeof expaWidgetIds === 'string') {
      const expaWidgetId = getExpaWidgetId(expaWidgetIds, expa)

      if (expaWidgetId) {
        return expaWidgetId
      }
    }

    return widgetId
  }, [expa, expaWidgetIds, widgetId])
}
