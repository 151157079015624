/* eslint-disable max-lines */
import { type IntlShape } from 'react-intl'

import {
  OfferV1NowProductGroupEnum,
  PrescriptionPrescriptionTypeEnum,
} from '@redteclab/api/clients/bully'

import { priceGetFormattedPrice } from '../../price'
import { TENANT } from '../../tenant'
import { queryStringEncode } from '../../url-handling'

import { type AlgoliaQueryRecord } from './AlgoliaQueryRecord'
import { type AlgoliaRecord, type PromotionType } from './AlgoliaRecord'

export const algoliaRecordAccessorGetImage = (
  record: AlgoliaRecord,
  fallback: string,
): string => {
  return record.image ?? fallback
}

const algoliaRecordAccessorHasPersonalVariantAttributes = (
  record: AlgoliaRecord,
): boolean => {
  if (!record.variant_attributes) {
    return false
  }

  return record.variant_attributes.some(
    (variantAttribute) => variantAttribute.personal,
  )
}

/**
 * on algolia record variant stock status is translated key.
 * available stock status is determined by the translation key: e.g. product.stockStatus.available.
 * "Available" statuses stock keys include words : .available, .delivery (note the dot)
 * @example:
 * product.stockStatus.available -> true
 * product.stockStatus.delivery5 -> true
 * product.stockStatus.notAvailable -> false
 * product.stockStatus.availableReplacement -> false
 */
const algoliaRecordAccessorIsAnyProductVariantAvailable = (
  record: AlgoliaRecord,
): boolean => {
  if (!record.variants) {
    return false
  }

  return record.variants.some((variant) => {
    if (!variant.stock) {
      return false
    }

    return ['.available', '.delivery'].some((it) => variant.stock?.includes(it))
  })
}

const shouldIncludeMasterQueryStringParam = (
  record: AlgoliaRecord,
): boolean => {
  return Boolean(
    algoliaRecordAccessorHasPersonalVariantAttributes(record) &&
      algoliaRecordAccessorIsAnyProductVariantAvailable(record),
  )
}

/**
 * gets deep link.
 * if link is relative, prefixes it so it's absolute.
 */
export const algoliaRecordAccessorGetDeeplink = ({
  params,
  record,
}: {
  params: {
    eventName?: string
    position: number
    query: string
    queryID: string
  }
  record: AlgoliaRecord
}): string => {
  if (!record.deeplink) {
    return ''
  }

  const deepLink = record.deeplink.startsWith('/')
    ? record.deeplink
    : `/${record.deeplink}`

  return `${deepLink}${queryStringEncode({
    ...params,
    eventName: decodeURIComponent(
      params.eventName ?? 'click on product in suggest',
    ),
    eventType: 'click',
    master: shouldIncludeMasterQueryStringParam(record),
    objectIDs: `[${record.objectID}]`,
  })}`
}

export const algoliaRecordAccessorShouldShowPriceStartsFrom = (
  record: AlgoliaRecord,
): boolean => {
  const hasMultipleVariants = (record.variants?.length ?? 0) > 1
  const hasMultipleAttributes = (record.variant_attributes?.length ?? 0) > 1
  const atLeastTwoVariantsAreStockAvailable =
    (record.variants?.filter(
      (it) => it.stock === 'product.stockStatus.available',
    ).length ?? 0) > 1

  return (
    hasMultipleAttributes &&
    hasMultipleVariants &&
    atLeastTwoVariantsAreStockAvailable
  )
}

export const algoliaRecordAccessorShouldShowListPrice = (
  record: AlgoliaRecord,
): boolean => {
  return record.listPrice ? record.listPrice !== record.price : false
}

/**
 * formatted price, listPrice and the discount percentage of cheapest variant with available stock
 */
export const algoliaRecordAccessorGetCheapestVariantFormattedPriceObject = (
  record: AlgoliaRecord,
  intl: IntlShape,
): {
  discount: number | undefined
  listPrice: string | undefined
  price: string | undefined
} => {
  const [cheapestVariant] = (record.variants ?? [])
    .filter((it) => it.stock === 'product.stockStatus.available')
    .sort((aVariant, bVariant) => {
      return aVariant.retailPrice > bVariant.retailPrice ? 1 : -1
    })

  if (!cheapestVariant.retailPrice) {
    return {
      discount: undefined,
      listPrice: undefined,
      price: undefined,
    }
  }
  const listPrice = cheapestVariant.listPrice
    ? priceGetFormattedPrice(cheapestVariant.listPrice / 100, intl)
    : undefined

  return {
    discount: cheapestVariant.discount,
    listPrice,
    price: priceGetFormattedPrice(cheapestVariant.retailPrice / 100, intl),
  }
}

const algoliaRecordAccessorShouldShowPrescriptionPrice = (
  record: AlgoliaRecord,
  tenant: TENANT,
): boolean => {
  return tenant === TENANT.COM && record.prescriptionMedicine
}

const algoliaRecordAccessorGetPrescriptionPrice = (
  record: AlgoliaRecord,
  intl: IntlShape,
): string | undefined => {
  const insurancePrice = record.best_offer?.prescription_prices?.find(
    (product) =>
      product.prescription_type ===
      PrescriptionPrescriptionTypeEnum.InsurancePrescription,
  )

  if (!insurancePrice) {
    return undefined
  }

  return priceGetFormattedPrice(insurancePrice.amount / 100, intl)
}

export const algoliaRecordAccessorGetPrice = (
  record: AlgoliaRecord,
  intl: IntlShape,
  tenant: TENANT,
): {
  discount: number | undefined
  listPrice: string | undefined
  price: string | undefined
} => {
  if (algoliaRecordAccessorShouldShowPriceStartsFrom(record)) {
    return algoliaRecordAccessorGetCheapestVariantFormattedPriceObject(
      record,
      intl,
    )
  }

  if (algoliaRecordAccessorShouldShowPrescriptionPrice(record, tenant)) {
    return {
      discount: undefined,
      listPrice: undefined,
      price: algoliaRecordAccessorGetPrescriptionPrice(record, intl),
    }
  }

  if (!record.price) {
    return {
      discount: undefined,
      listPrice: undefined,
      price: undefined,
    }
  }

  return {
    discount: record.discountInPercent,
    listPrice: priceGetFormattedPrice(record.listPrice / 100, intl),
    price: priceGetFormattedPrice(record.price / 100, intl),
  }
}

export const algoliaRecordAccessorGetCapitalizedQuery = (
  items: AlgoliaQueryRecord[],
): AlgoliaQueryRecord[] => {
  return items.map((hit) => ({
    ...hit,
    // Check if string is empty before performing capitalisation
    query: hit.query.trim()
      ? hit.query.charAt(0).toUpperCase() + hit.query.slice(1)
      : hit.query,
  }))
}

export const algoliaRecordAccessorGetFormattedPricePerUnit = (
  record: AlgoliaRecord,
  intl: IntlShape,
): string | undefined => {
  const { pricePerUnit } = record

  if (!pricePerUnit) {
    return undefined
  }

  // we need to change 'st' to 'St.' for german lang shops since the nouns should start with capital
  return pricePerUnit.replace(
    / st$/u,
    ` ${intl.formatMessage({ id: 'product.attribute.unit.pcs' })}`,
  )
}

const SAE_OFFER_TYPE = 'sae'

const algoliaRecordAccessorIsSaeOffer = (record: AlgoliaRecord): boolean => {
  const offer = record.best_offer

  return offer?.type.toLowerCase() === SAE_OFFER_TYPE
}

export const algoliaRecordAccessorNowProductGroupIsSameDayNextDay = (
  record: AlgoliaRecord,
): boolean => {
  if (!algoliaRecordAccessorIsSaeOffer(record)) {
    return false
  }

  return record.nowProductGroup === OfferV1NowProductGroupEnum.SamedayNextday
}

/**
 * returns true if the current time is less than end data and more than start data
 * for cases:
 * - no start date - true if lt enddate
 * - no end date - true if gt startdate
 * - both start and end date - true if gt startdate and lt enddate
 */
export const algoliaRecordAccessorGetCurrentTimeIsInRedpointsTimeframe = (
  record: AlgoliaRecord,
): boolean => {
  const { RPEndDate, RPStartDate } = record

  const endDate = new Date(RPEndDate ?? 0).getTime()
  const startDate = new Date(RPStartDate ?? 0).getTime()
  const now = Date.now()

  const noEndDateAndNowIsAfterStart = endDate === 0 && now > startDate

  if (noEndDateAndNowIsAfterStart) {
    return true
  }

  const noStartDateAndNowIsBeforeEnd = startDate === 0 && endDate > now

  if (noStartDateAndNowIsBeforeEnd) {
    return true
  }

  return now >= startDate && now <= endDate
}

/**
 * returns redpoints multiplier
 * defaults to 0 if less than 1 (in legacy code it could be e.g. -1)
 */
export const algoliaRecordAccessorGetRedPointsMultiplier = (
  record: AlgoliaRecord,
): number => {
  const redPoints = record.best_offer?.redpoints

  const multiplier = redPoints?.multiplier ?? 0

  if (multiplier < 1) {
    return 0
  }

  return multiplier
}

export const algoliaRecordAccessorGetPromotionType = (
  record: AlgoliaRecord,
): PromotionType | undefined => {
  if (!algoliaRecordAccessorIsSaeOffer(record)) {
    return undefined
  }

  return record.best_offer?.promotion?.type ?? record.promotion
}
