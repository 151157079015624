/**
 * Sub-folder in Google Storage bucket https://github.com/RedTecLab/home-one-assets/blob/550f28c64cf54866cafda945efec86534508ea10/.circleci/config.yml#L56
 */
const BRAND_ILLUSTRATION_FOLDER = 'brand-illustrations'

/**
 * Image names as provided in https://github.com/RedTecLab/home-one-assets/tree/main/packages/brand-illustrations
 */
export type BrandIllustrationName =
  | 'consent'
  | 'rx'
  | 'free-delivery'
  | 'empty-cart'
  | 'product-range'
  | 'order-cancelled'
  | 'empty-inbox'
  | 'empty-order-list'
  | 'empty-payments'
  | 'empty-wishlist'
  | 'order-being-checked'
  | 'ratings'
  | 'waiting-for-payment-in-euro'
  | 'waiting-for-payment-in-chf'
  | 'redirect'
  | 'medication-plan'
  | 'filled-inbox'
  | 'nfc-and-checkmark'
  | 'nfc-and-nfc-sign'
  | 'message'
  | 'patient-care'
  | 'customer-support'
  | 'web-camera'
  | 'ods-consultation'
  | 'delivered'
  | 'offset-eco'
  | 'planetary-care'
  | 'credit-balance-in-euro'
  | 'credit-balance-no-currency'
  | 'apps'
  | 'ods'
  | 'offline-doctor'
  | 'directions'
  | 'own-brands'
  | 'interaction-check'
  | 'swap'
  | 'repeat-prescription'
  | 'filled-mailbox'
  | 'update'
  | 'completed'
  | 'confirmed'
  | 'coupon'
  | 'missing-products'
  | 'survey'
  | 'notification'
  | 'scan-a-product'
  | 'complete-the-order'
  | 'e-rezept-spaceholder'
  | 'list-of-products'
  | 'filled-cart'
  | 'pick-up'
  | 'under-construction'
  | 'sale-code'
  | 'copy-sale-code'
  | 'news'
  | 'newsletter'
  | 'forgotten-password'
  | 'present'
  | 'party-balloons'
  | 'unknown-location'
  | 'now'
  | 'standard-delivery'
  | 'tracking'
  | 'scan-qr-code'
  | 'scan-qr-code-on-mobile'
  | 'insurance-number'
  | 'choose-an-image'
  | 'make-a-selection'
  | 'redpoints-redeemed'
  | 'red-points'
  | 'measure-eco'
  | 'reduce-eco'
  | 'employee-care'
  | 'sun'
  | 'beauty'
  | 'pets'
  | 'diets-sports'
  | 'avatar-female'
  | 'austrian-flags'
  | 'italian-flags'
  | 'french-flags'
  | 'austrian-heart-discount'
  | 'italian-heart-discount'
  | 'french-heart-discount'
  | 'austrian-flag'
  | 'french-flag'
  | 'swiss-flags'
  | 'swiss-sign-discount'
  | 'back-to-school'
  | 'medical-equipment'
  | 'medical-product'
  | 'vegan-cosmetics'
  | 'vegan-products'
  | 'maternity'
  | 'pregnancy'
  | 'easter-eggs'
  | 'easter-bunny'
  | 'baby-family'
  | 'hand-washing'
  | 'health'
  | 'winter'
  | 'sport'
  | 'pharmacy'
  | 'piggy-bank-with-euro'
  | 'piggy-bank-with-chf'
  | 'product-bundle'
  | 'flexible-schedule'
  | 'social-security'
  | 'cologne'
  | 'pharmacist'
  | 'sustainable-product'
  | 'avatar-male'
  | 'avatar-divers'
  | 'expertise'
  | 'growth'
  | 'hashtag'
  | 'movie-camera'

/*
 * Until we have Celum integration we provide scales only for images
 * we upload manually to Google Storage bucket
 */
type BrandIllustrationScale = 1 | 2

export type BrandIllustrationSize = 's' | 'm'

export type BrandIllustrationType = 'dark' | 'light' | 'clear'

/**
 * `clear` brand illustration have square aspect ratio, while `dark` and `light` have 9:8
 */
const variantSizes: Record<
  `${BrandIllustrationSize}-${BrandIllustrationType}`,
  {
    height: number
    width: number
  }
> = {
  'm-clear': { height: 144, width: 144 },
  'm-dark': { height: 144, width: 162 },
  'm-light': { height: 144, width: 162 },
  's-clear': { height: 96, width: 96 },
  's-dark': { height: 96, width: 108 },
  's-light': { height: 96, width: 108 },
}

/**
 * Generates brand illustration image URL & provide the fixed size for it
 *
 * @example
 * ```json
 *  {
 *    height: 144,
 *    src: https://cdn.ref-sa-tech.io/home-one-assets/brand-illustrations/empty-cart-m-dark-2x.png,
 *    width: 162
 *  }
 * ```
 */
export const brandIllustrationGetImage = ({
  cdnUrl,
  name,
  scale = 1,
  size,
  type,
}: {
  cdnUrl: string
  name: BrandIllustrationName
  scale?: BrandIllustrationScale
  size: BrandIllustrationSize
  type: BrandIllustrationType
}): {
  height: number
  src: string
  width: number
} => {
  let fileName = `${name}-${size}-${type}`

  if (scale > 1) {
    fileName = `${fileName}-${scale}x`
  }

  return {
    ...variantSizes[`${size}-${type}`],
    src: `${cdnUrl}${BRAND_ILLUSTRATION_FOLDER}/${fileName}.png`,
  }
}
